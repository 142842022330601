#ContactHeader {
  font-family: Montserrat;
  font-size: 45px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #2b2a2c;
}
#contactSection {
  margin-bottom: 50px;
}
#details {
  display: flex;
  justify-content: space-around;
}

.miniDetails {
  font-size: 14px;
}
#contactDescription {
  font-size: 13px;
  line-height: 1.71;
  font-weight: 300;
  text-align: left;
  color: #6a696b;
  text-align: center;
}
.miniDetails_heading {
    font-weight: bold;
}
