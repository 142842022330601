#skillsSection {
  margin-top: 20px;
  margin-bottom: 50px;
}
#skillsHolder {
  padding: 0 195px;
}
#mySkillsHeading {
  font-family: Montserrat;
  font-size: 45px;
  font-weight: bold;
  line-height: 1;
  color: #2b2a2c;
  margin: 0px;
  text-align: center;
}
.skillName {
  font-weight: bold;
  margin: 0px;
}
.skills {
  /* background-color: #1869ff; */
  background-color: #0093c4;
  color: white;
  text-align: right;
  padding: 5px;
}
.skillbar {
  background-color: #e0e0e0;
}
.skillPercent {
  font-weight: bold;
  font-size: 14px;
}
#skillsHolder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.Skillcontainer {
  flex: 0 0 50%;
  padding-left: 40px;
  margin: 0;
}
#skillDescription {
  font-size: 13px;
  line-height: 1.71;
  font-weight: 300;
  text-align: left;
  color: #6a696b;
  text-align: center;
}
