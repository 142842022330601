#intro_container {
  display: flex;
  flex-direction: row;
  padding: 0 132px;
}
#secondPart {
  display: none;
}
#secondPart > img {
  height: 40vh;
  width: auto;
}
#intro_name {
  font-family: Montserrat;
  font-size: 45px;
  font-weight: bold;
  text-align: left;
  color: #2b2a2c;
  padding-top: 40px;
}

#light_hello {
  font-size: 32px;
  font-weight: 200;
  color: #a5a5a5;
  margin: 0px;
}
#myName {
  margin: 0px;
  padding: 10px 0;
}

.software_engineer {
  font-family: Montserrat;
  font-size: 14px;
  color: #ababac;
  font-weight: bold;
  margin: 0px;
  padding-bottom: 10px;
}
#shareButtons {
  display: flex;
  font-size: 12px;
}
#actionButtons {
  display: flex;
  margin-top: 10px;
}
#shareButtons > a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 0px 6px;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  margin-right: 5px;
}
#shareButtons > a:hover {
  text-decoration: underline;
}
#actionButtons > a {
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  padding: 0 40px;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  margin-right: 5px;
}

#hireme {
  color: white;
  background-color: #0093c4;
}

#resume {
  color: black;
}
#resume:hover,#hireme:hover {
  text-decoration: underline;
}

#blogs {
  font-weight: bold;
}
.blogLink > a{
  text-decoration: none;
  font-size: 14px;
  color: #ababac;
}
.blogLink >a:hover{
  color:black;
  text-decoration: underline;
}
