@media (max-width: 480px) {
  .singleRepoProject,
  .appOnStore {
    flex: 0 0 90%;
  }
  .Skillcontainer {
    flex: 0 0 100%;
    padding-left: 0px;
  }
  #skillsHolder {
    padding: 0 20px;
  }
  #about_container {
    padding: 0 20px;
  }
  #whoamI_aboutme {
    font-size: 60px;
  }
  #projectContainer,
  #intro_container {
    padding: 0 20px;
  }

  #actionButtons {
    flex-direction: column;
  }
  #actionButtons > a {
    margin-top: 10px;
  }
  #navbar {
    display: none;
  }
  #icon {
    display: block;
  }
  #navbar-container {
    align-items:stretch;
  }
  #header-left-section{
    padding:0 10px;
  }
  .sticky + #intro{
    padding-top: 47.88px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .singleRepoProject,
  .appOnStore {
    flex: 0 0 90%;
  }
  .Skillcontainer {
    flex: 0 0 100%;
    padding-left: 0px;
  }
  #skillsHolder {
    padding: 0 20px;
  }
  #about_container {
    padding: 0 20px;
  }
  #whoamI_aboutme {
    font-size: 60px;
  }
  #projectContainer,
  #intro_container {
    padding: 0 20px;
  }
  .sticky + #intro{
    padding-top: 110.54px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  #navbar-container {
    flex-direction: column;
  }

  .singleRepoProject {
    flex: 0 0 45%;
  }
  
.sticky + #intro{
  padding-top: 110.54px;
}

}

@media (min-width: 1025px) {
  #navbar-container {
    flex-direction: row;
    justify-content: space-between;
  }
  #intro_container {
    align-items: center;
  }
  #firstPart {
    padding-left: 20px;
    order: 2;
  }
  #secondPart {
    flex: 0 0 30%;
    display: block;
  }
  #secondPart > img {
    border-radius: 50px;
  }
  #header-left-section {
    padding-left: 132px;
  }
  #navbar {
    display: flex;
    padding-right: 132px;
  }
  #intro_name {
    padding-top: 60px;
  }
  
.sticky + #intro{
  padding-top: 64.67px;
}
}

