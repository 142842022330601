body,
html {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}