
#navbar-container,
#navbar {
  display: flex;
}
#header-left-section {
  font-size: 16px;
}
#navbar {
  list-style: none;
  padding: 0px;
  font-size: 14px;
}
#header-left-section a,
#navbar > li > a {
  text-decoration: none;
  display: block;
  font-weight: bold;
  color: black;
}
#navbar > li > a {
  padding: 10px 15px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #4fc3f7;
}
#navbar-container {
  flex-direction: column;
  align-items: center;
}
#icon{
  display: none;
  float:right;
}
