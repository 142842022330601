#projectContainer_Heading {
  font-family: Montserrat;
  font-size: 35px;
  font-weight: bold;
  margin: 0px;
  text-align: left;
  color: #2b2a2c;
  text-align: center;
}
#projectContainer {
  padding: 0 132px;
}
/* Apps on playstore section css */
#appsContainer {
  display: flex;
  flex-wrap: wrap;
}
.appOnStore {
  display: flex;
  flex: 0 0 50%;
  align-items: center;
}
.appImage {
  border-radius: 100px;
  height: 120px;
  align-items: center;
}
.appDetails {
  padding: 0 20px;
}
.appName {
  font-size: 18px;
  font-weight: bold;
}
.appCategory {
  color: #828282;
  font-size: 12px;
  font-weight: bold;
}
.appDescription,
.singleRepoDescription {
  font-size: 13px;
  line-height: 1.71;
  font-weight: 300;
  text-align: left;
  color: #6a696b;
}
.appLink > a {
  text-decoration: none;
  padding: 10px;
  font-size: 12px;
  color: black;
  border-radius: 5px;
  border: 1px solid #dddddd;
}
.appLink > a:hover {
  text-decoration: underline;
}
/* Projects on Github */
#projectsOnGit {
  margin-top: 40px;
}
#loader_image {
  height: 80px;
}
#mainProjectRepoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.singleRepoProject {
  flex-basis: calc(30%);
  padding: 10px 20px;
  margin-bottom: 20px;
  border: 1px solid #ecebeb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.singleRepoName {
  font-weight: bold;
}

.repoLink > a {
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  color: #737373;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 10px;
}
.repoLink > a:hover{
  text-decoration: underline;
}

