#aboutSection {
  margin-top: 20px;
}
#about_container {
  padding: 0 132px;
}
#aboutme_firstPart {
  flex: 0 0 40%;
}
#aboutme_firstPart > img {
  width: 100%;
  height: 100%;
  max-width: 450px;
}
#aboutme_secondPart {
  padding: 10px 0;
}
#aboutme_heading {
  font-family: Montserrat;
  font-size: 45px;
  font-weight: bold;
  margin: 0px;
  text-align: left;
  color: #2b2a2c;
}
#whoamI_aboutme {
  margin: 0px;
  font-size: 92px;
  font-weight: bold;
  color: #2b2a2c4a;
}
#aboutme_description {
  font-size: 13px;
  line-height: 1.71;
  font-weight: 300;
  text-align: left;
  color: #6a696b;
}
#specialization {
  display: flex;
  flex-direction: row;
}
.certificate_link > a {
  text-decoration: none;
  color: gray;
}
.certificate_link > a:hover {
  text-decoration: underline;
  color: black;
}
#mobile_web,
#machine_learning {
  padding: 10px 20px;
  font-size: 14px;
}
.specializationName {
  font-weight: bold;
}
